// https://a.yandex-team.ru/arcadia/taxi/backend-py3/services/hiring-partners-app/docs/yaml/api/feeds.yaml

import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  type UseInfiniteQueryOptions,
  type UseMutationOptions,
  type UseQueryOptions,
} from "@superweb/api";
import { useLocale } from "@superweb/intl";

import { fetch } from "#internal/api/fetch";
import type * as api from "#internal/api/generated/hiring-partners-app";

export type NotificationFeed =
  UseV1FeedsNotificationListPostResponseOk["feeds"][number];

export type NotificationFeedLast =
  UseV1FeedsLastServicesFeedResponseOk["notification"];

export type Banner = UseV1FeedsLastServicesFeedResponseOk["banner"];

type LanguageHeaders = ReturnType<typeof useLanguageHeaders>;
const useLanguageHeaders = () => {
  const { baseName } = useLocale();
  return { "Accept-Language": baseName };
};

export const v1FeedsQueryKeys = {
  summaryPost: (params?: {
    headers: LanguageHeaders;
    body: UseV1FeedsSummaryPostRequest["body"];
  }) =>
    params === undefined
      ? (["/api/v1/feeds/summary"] as const)
      : (["/api/v1/feeds/summary", params] as const),
  notificationListPost: (params?: {
    headers: LanguageHeaders;
    body: UseV1FeedsNotificationListPostRequest["body"];
  }) =>
    params === undefined
      ? (["/api/v1/feeds/list"] as const)
      : (["/api/v1/feeds/list", params] as const),
};

type UseV1FeedsSummaryPostRequest =
  api.paths["/v1/feeds/summary"]["POST"]["request"];
type UseV1FeedsSummaryPostResponse =
  api.paths["/v1/feeds/summary"]["POST"]["response"];
export type UseV1FeedsSummaryPostResponseOk = Extract<
  UseV1FeedsSummaryPostResponse,
  { status: 200 }
>["body"];

export const useV1FeedsSummaryPost = (
  options?: UseQueryOptions<UseV1FeedsSummaryPostResponseOk>,
) => {
  const headers = useLanguageHeaders();
  const body: UseV1FeedsSummaryPostRequest["body"] = {
    feed_type: "notification",
  };
  return useQuery<UseV1FeedsSummaryPostResponseOk, unknown>(
    v1FeedsQueryKeys.summaryPost({ headers, body }),
    () =>
      fetch(
        new Request("/api/v1/feeds/summary", {
          method: "POST",
          credentials: "include",
          headers,
          body: JSON.stringify(body),
        }),
        {
          ok: (r) => r.json(),
        },
      ),
    options,
  );
};

type PaginationCursor = string;

type UseV1FeedsNotificationListPostParams = { tag?: string };
type UseV1FeedsNotificationListPostRequest =
  api.paths["/v1/feeds/list"]["POST"]["request"];
type UseV1FeedsNotificationListPostResponse =
  api.paths["/v1/feeds/list"]["POST"]["response"];
type UseV1FeedsNotificationListPostResponseOk = Extract<
  Extract<UseV1FeedsNotificationListPostResponse, { status: 200 }>["body"],
  { feed_type: "notification" }
>;

export const useV1FeedsNotificationListPost = (
  params: UseV1FeedsNotificationListPostParams,
  options?: UseInfiniteQueryOptions<UseV1FeedsNotificationListPostResponseOk>,
) => {
  const headers = useLanguageHeaders();
  const body: UseV1FeedsNotificationListPostRequest["body"] = {
    feed_type: "notification",
    filter: params.tag ? { tags: [params.tag] } : undefined,
  };

  return useInfiniteQuery(
    v1FeedsQueryKeys.notificationListPost({ headers, body }),
    ({ pageParam: cursor }: { pageParam?: PaginationCursor }) => {
      return fetch(
        new Request("/api/v1/feeds/list", {
          method: "POST",
          credentials: "include",
          headers,
          body: JSON.stringify({ ...body, cursor }),
        }),
        {
          ok: (r) => r.json(),
        },
      );
    },
    {
      getNextPageParam: ({ cursor }) => {
        if (!cursor) return undefined;
        return cursor;
      },
      ...options,
    },
  );
};

type UseV1FeedNotificationMarkReadRequest =
  api.paths["/v1/feeds/set-status"]["POST"]["request"];

type UseV1FeedNotificationMarkReadParams = {
  feedId: string;
  feedType: UseV1FeedNotificationMarkReadRequest["body"]["feed_type"];
};

export const useV1FeedNotificationMarkRead = (
  options?: UseMutationOptions<
    boolean,
    unknown,
    UseV1FeedNotificationMarkReadParams
  >,
) => {
  return useMutation(
    ({ feedId, feedType }: UseV1FeedNotificationMarkReadParams) => {
      const body: UseV1FeedNotificationMarkReadRequest["body"] = {
        feed_type: feedType,
        status: "read",
        feed_id: feedId,
      };
      return fetch(
        new Request("/api/v1/feeds/set-status", {
          method: "POST",
          credentials: "include",
          body: JSON.stringify(body),
        }),
        {
          ok: (r) => Promise.resolve(r.ok),
        },
      );
    },
    options,
  );
};

type UseV1FeedNotificationMarkReadAllRequest =
  api.paths["/v1/feeds/set-status/batch"]["POST"]["request"];

export const useV1FeedNotificationMarkReadAll = (
  options?: UseMutationOptions<boolean, unknown, NotificationFeed["feed_id"]>,
) => {
  return useMutation((firstFeedId: NotificationFeed["feed_id"]) => {
    const body: UseV1FeedNotificationMarkReadAllRequest["body"] = {
      feed_type: "notification",
      status: "read",
      first_feed_id: firstFeedId,
    };
    return fetch(
      new Request("/api/v1/feeds/set-status/batch", {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(body),
      }),
      {
        ok: (r) => Promise.resolve(r.ok),
      },
    );
  }, options);
};

export type UseV1FeedsLastServicesFeedResponse =
  api.paths["/v1/feeds/last-services-feed"]["GET"]["response"];

type UseV1FeedsLastServicesFeedResponseOk = Extract<
  UseV1FeedsLastServicesFeedResponse,
  { status: 200 }
>["body"];

export type UseV1FeedsLastServicesFeedOptions =
  UseQueryOptions<UseV1FeedsLastServicesFeedResponseOk>;

export const useV1FeedsLastServicesFeed = (
  options?: UseV1FeedsLastServicesFeedOptions,
) => {
  const url = "/api/v1/feeds/last-services-feed";
  return useQuery<UseV1FeedsLastServicesFeedResponseOk, unknown>(
    [url],
    () =>
      fetch(
        new Request(url, {
          method: "GET",
          credentials: "include",
        }),
        {
          ok: (r) => r.json(),
        },
      ),
    options,
  );
};

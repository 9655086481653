import {
  createContext,
  useContext,
  type HTMLAttributeAnchorTarget,
  type PointerEvent,
  type ReactNode,
  type RefObject,
} from "react";

import type {
  Banner,
  NotificationFeedLast,
} from "../api/hiring-partners-app/feeds";

export type MenuLink = {
  type: "link";
  icon: ReactNode;
  label: string;
  id?: string;

  link: {
    href: string;
    onClick?: (event: PointerEvent<HTMLAnchorElement>) => void;
    target?: HTMLAttributeAnchorTarget;
    isActive?: boolean;
  };
};

export type NotificationCenterState = {
  unreadNotifications?: number;
  totalNotifications?: number;
  open: boolean;
  switchNotificationCenterOpen: (value?: boolean) => boolean;

  menuRef?: RefObject<HTMLDivElement>;
  menuItem?: MenuLink;

  banner?: Banner;
  closeBanner: () => void;
  notification?: NotificationFeedLast;
  closeNotification: () => void;
};
export type NotificationCenterExternalState = Pick<
  NotificationCenterState,
  "open" | "menuRef" | "menuItem"
>;

export const NotificationCenterContext = createContext<NotificationCenterState>(
  {
    open: false,
    switchNotificationCenterOpen: () => false,
    closeBanner: () => undefined,
    closeNotification: () => undefined,
  },
);

export const useNotificationCenterState = () =>
  useContext(NotificationCenterContext);

export const useNotificationCenterExternalState =
  (): NotificationCenterExternalState => useContext(NotificationCenterContext);

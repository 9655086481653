/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { Suspense, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Routes, Route, Navigate, Outlet, useLocation } from "react-router-dom";

import {
  LocaleProvider,
  useNegotiatedLocale,
  usePreferredLocales,
} from "@superweb/intl";
import { CssOptions, cssFns } from "@superweb/css";
import {
  AppContainer,
  Spin,
  lazy,
  useTypo,
  useUiColors,
  Snackbar,
  AppLayout,
  useIsMobile,
  Messages as UiMessages,
} from "@superweb/ui";
import { useFlag } from "@superweb/flags";

import { Errors } from "#internal/ui/errors";
import {
  type Language,
  availableLocales,
  isValidLanguage,
  Messages,
  useMessage,
} from "#internal/intl";
import { setVars, updateErrors } from "#internal/rum";
import { HttpErrors } from "#internal/http-errors";
import { useMetrika } from "#internal/metrika";
import { useRemoteVersion } from "#internal/version";
import { FlagsProvider } from "#internal/flags-provider";
import { Menu } from "#internal/menu";

import { useMe } from "#internal/api/hiring-partners-app/users";
import {
  RedirectFromTeamReferralAltLink,
  ReferralAltLinkCheck,
} from "#internal/agents/referral-alt";
import {
  RedirectFromTeamReferralLink,
  ReferralLinkCheck,
} from "#internal/agents/team-referral";
import { UserStatus } from "#internal/ui/activation-status";

import {
  NotificationCenterProvider,
  NotificationOverlay,
} from "./notification-center";

const { Settings } = lazy(() => import("#internal/settings"));

const { AgentsPage } = lazy(() => import("#internal/agents/page"));
const { LeadPage } = lazy(() => import("#internal/agents/lead-page"));
const { VacancyChoose } = lazy(
  () => import("#internal/agents/create-lead/vacancy-choose"),
);
const { CreateLeadPage } = lazy(
  () => import("#internal/agents/create-lead/lead-create/lead-create-page"),
);

const { Billing } = lazy(() => import("#internal/billing/page"));
const { Info } = lazy(() => import("#internal/info/page"));
const { InfoDetails } = lazy(() => import("#internal/info/info-details"));
const { Leads } = lazy(() => import("#internal/leads/page"));
const { Tariffs } = lazy(() => import("#internal/tariffs/page"));
const { Users } = lazy(() => import("#internal/users/page"));
const { NotFound } = lazy(() => import("#internal/not-found"));
const { FindUser } = lazy(
  () => import("#internal/activators/find-user/find-user"),
);
const { GenerateRefLink } = lazy(() => import("#internal/teamleads/ref-link"));
const { TeamleadDashboard } = lazy(
  () => import("#internal/teamleads/dashboard"),
);
const { FinancesPage } = lazy(() => import("#internal/finances"));
const { ScoutPage } = lazy(() => import("#internal/scout"));
const { RejectedDocuments } = lazy(
  () => import("#internal/rejected-documents"),
);

const MetrikaLogs = () => {
  const mounted = useRef(false);
  const { userParams, params } = useMetrika();
  const { refetch } = useMe({
    retry: false,
    suspense: true,
    enabled: false,
    onSuccess: ({ data: { personal_yandex_login_id, permission_flags } }) => {
      userParams({
        UserID: personal_yandex_login_id,
      });
      params({ permission_flags });
    },
  });

  useEffect(() => {
    if (!mounted.current) {
      void refetch();
      mounted.current = true;
    }
  }, [refetch]);
  return null;
};

const SectionLayout = () => {
  const isMobile = useIsMobile();
  const { pathname } = useLocation();

  return (
    <div
      css={{
        position: "relative",
        display: "grid",
        gridTemplateColumns: "100%",
        minWidth: "320px",
        ...(!isMobile && { gridColumnStart: "2" }),
        ...cssFns.gridRow("1", "-1"),
        gridTemplateRows: "1fr",
        height: "100%",
      }}
    >
      <Errors resetKey={[pathname]}>
        <Suspense
          fallback={
            <div
              css={{
                display: "grid",
                ...cssFns.placeContent("center"),
              }}
            >
              <Spin />
            </div>
          }
        >
          {!isMobile && <NotificationOverlay />}
          <Outlet />
        </Suspense>
      </Errors>
    </div>
  );
};

const AppRoutes = () => {
  const flag = useFlag();

  return (
    <Routes>
      <Route element={<SectionLayout />}>
        {flag({
          id: "8684f191-132f-4400-a524-3ea2de245003",
          description: "Show activation list page",
        }) && <Route path="activators/find_users" element={<FindUser />} />}

        {flag({
          id: "e5c8fe3c-2a26-4694-ad7d-15ad6028768c",
          description: "Show generate ref link page",
        }) && (
          <Route
            path="teamlead/generate_ref_link"
            element={<GenerateRefLink />}
          />
        )}

        {flag({
          id: "195292d0-ac81-4673-92aa-beb5b432fbfb",
          description: "Show teamlead dashboard page",
        }) && (
          <Route path="teamlead/dashboard" element={<TeamleadDashboard />} />
        )}

        {flag({
          id: "2c5b1670-8f88-43b6-9633-673720b67c71",
          description: "Show scout page",
        }) && <Route path="scout/:id" element={<ScoutPage />} />}

        {flag({
          id: "baad11a3-cd13-4b0a-a6e3-6222eda2ec5b",
          description: "Show lead create page",
        }) && <Route path="create-lead" element={<CreateLeadPage />} />}

        {flag({
          id: "b12b6599-bba4-467b-bb19-96bfa0e345fb",
          description: "Show vacncy choose page",
        }) && <Route path="vacancy-choose" element={<VacancyChoose />} />}

        {flag({
          id: "9ce6ef50-bcc9-43dc-9ec8-0fdd7b6e6ffb",
          description: "View finances page",
        }) && <Route path="finances" element={<FinancesPage />} />}

        {flag({
          id: "524b51f2-41cb-48f9-b20b-a012fcf775a9",
          description: "Show agents page",
        }) && (
          <>
            <Route path="agents" element={<AgentsPage />} />
            <Route path="agents/lead/:id" element={<LeadPage />} />
            <Route index element={<Navigate to="agents" replace />} />
          </>
        )}

        {flag({
          id: "fb088a44-a66f-44b6-bc73-dde475819c69",
          description: "Show leads page",
        }) && (
          <>
            <Route path="leads" element={<Leads />} />
            <Route index element={<Navigate to="leads" replace />} />
          </>
        )}

        {flag({
          description: "Show eats tarriffs page",
          id: "03ffb010-bc28-4dbc-9689-ec79ad16739c",
        }) && <Route path="tariffs" element={<Tariffs />} />}

        {flag({
          id: "8eceb50f-e5ac-438f-a4f2-f69b1aa9298a",
          description: "Show users page",
        }) && <Route path="users" element={<Users />} />}

        {flag({
          id: "7bee9ef1-1eeb-42ad-b756-3f0627108225",
          description: "Show eats info page",
        }) && <Route path="info" element={<Info />} />}

        {flag({
          id: "6ecaf922-49aa-4375-99e8-1da9f4372be5",
          description: "Show eats info page",
        }) && <Route path="info/:detailId" element={<InfoDetails />} />}

        {flag({
          id: "b0b387fd-e025-47a7-b02b-320ae3420080",
          description: "Show billing page",
        }) && <Route path="billing" element={<Billing />} />}

        <Route path="settings" element={<Settings />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

const Content = ({
  onLanguageChange,
}: {
  onLanguageChange: (language: string | undefined) => void;
}) => {
  const { data: { data } = {}, refetch } = useMe({
    onSuccess: ({ data: { locale } }) => {
      onLanguageChange(locale);
    },
  });

  const message = useMessage();

  if (data?.status === "created") return <UserStatus status="created" />;
  if (data?.status === "deactivated")
    return <UserStatus status="deactivated" />;

  if (data?.status === "rejected") {
    return (
      <RejectedDocuments
        countryCode={data.country_code ?? "rus"}
        onSuccess={refetch}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {message({
            id: "57001615-e7c4-44a9-8fa6-a4db200239c2",
            context: "Default page title that is displayed in the search bar",
            default: "Partner account",
          })}
        </title>
      </Helmet>

      <AppRoutes />
    </>
  );
};

export const App = () => {
  const [fetchedLocale, setFetchedLocale] = useState<Language>();
  const preferredLocales = usePreferredLocales();

  const locale = useNegotiatedLocale({
    requested: fetchedLocale ?? preferredLocales,
    available: availableLocales,
    default: fetchedLocale ?? "en",
  });

  const onLanguageChange = (language: string | undefined) => {
    isValidLanguage(language)
      ? setFetchedLocale(language)
      : setFetchedLocale(undefined);
  };

  const { pathname } = useLocation();

  useEffect(() => {
    setVars({ "-page": pathname });
    updateErrors({ page: pathname });
  }, [pathname]);

  return (
    <CssOptions
      value={{
        nonce: (
          document.querySelector(
            "meta[property=csp-nonce]",
          ) as HTMLMetaElement | null
        )?.nonce,
      }}
    >
      <LocaleProvider value={locale}>
        <Suspense fallback={<AppFallback />}>
          <UiMessages>
            <Messages>
              <AppContainer>
                <Routes>
                  <Route
                    path="/team_ref/:teamRefId"
                    element={
                      <RedirectFromTeamReferralLink
                        onLanguageChange={onLanguageChange}
                      />
                    }
                  />
                  <Route
                    path="/team_ref/:teamRefId/complete"
                    element={
                      <ReferralLinkCheck onLanguageChange={onLanguageChange} />
                    }
                  />
                  <Route
                    path="/ref_alt/:refId"
                    element={
                      <RedirectFromTeamReferralAltLink
                        onLanguageChange={onLanguageChange}
                      />
                    }
                  />
                  <Route
                    path="/ref_alt/:refId/complete"
                    element={
                      <ReferralAltLinkCheck
                        onLanguageChange={onLanguageChange}
                      />
                    }
                  />
                  <Route
                    path="*"
                    element={
                      <HttpErrors>
                        <MetrikaLogs />
                        <FlagsProvider>
                          <NotificationCenterProvider>
                            <AppLayout
                              menu={<Menu />}
                              content={
                                <Content onLanguageChange={onLanguageChange} />
                              }
                            />
                          </NotificationCenterProvider>
                          <VersionBar />
                        </FlagsProvider>
                      </HttpErrors>
                    }
                  />
                </Routes>
              </AppContainer>
            </Messages>
          </UiMessages>
        </Suspense>
      </LocaleProvider>
    </CssOptions>
  );
};

const AppFallback = () => {
  return (
    <div
      css={{
        display: "grid",
        alignContent: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Spin />
    </div>
  );
};

const VersionBar = () => {
  const typo = useTypo();
  const uiColors = useUiColors();
  const message = useMessage();
  const flag = useFlag();
  const [snackIsOpen, setSnackOpen] = useState(false);
  const [isPolling, setPolling] = useState(
    flag({
      id: "6578b137-40da-452e-977f-d22fe2fc5102",
      description: "Show version snackbar message",
    }),
  );

  useRemoteVersion({
    refetchInterval: 1000 * 60,
    enabled: isPolling,
    onSuccess(data) {
      if (
        data.version &&
        import.meta.env.VERSION &&
        data.version !== import.meta.env.VERSION
      ) {
        setSnackOpen(true);
        setPolling(false);
      }
    },
  });

  return (
    <>
      {snackIsOpen && (
        <Snackbar
          text={message({
            id: "cc76dc07-c776-4468-91b4-99060e276b64",
            context: "Has new app version message",
            default: "New version is available, reload the page",
          })}
          action={message({
            id: "054222a9-be23-4156-bad4-299b05ad402d",
            context: "Has new app version update button",
            default: "Update",
          })}
          onClose={() => {
            setSnackOpen(false);
          }}
          onAction={() => {
            window.location.reload();
          }}
        />
      )}

      <div
        css={{
          position: "fixed",
          bottom: "0px",
          left: "0px",
          ...typo({ weight: "regular", density: "loose", level: "caption2" }),
          color: cssFns.setOpacity(uiColors.textMinor, 0.4),
        }}
      >
        v:{import.meta.env.VERSION}
      </div>
    </>
  );
};

/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { LightningIcon } from "#internal/ui/lightning-icon";
import type { Banner } from "#internal/api/hiring-partners-app/feeds";

import { Button, icons } from "@superweb/ui";
import { useTypo, useIsMobile, useUiColors } from "@superweb/ui";

import { Markdown } from "./markdown";
import { useNotificationMetrika } from "../metrika";

type BannerNotificationProps = {
  banner: NonNullable<Banner>;
  closeBanner: () => void;
};

export const BannerNotification = ({
  banner,
  closeBanner,
}: BannerNotificationProps) => {
  const typo = useTypo();
  const isMobile = useIsMobile();
  const uiColors = useUiColors();
  const { closeBannerMetrika } = useNotificationMetrika();

  const closeBannerNotification = () => {
    closeBanner();
    closeBannerMetrika();
  };

  return (
    <div
      css={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: isMobile ? "60px" : "48px",
        paddingInlineStart: "8px",
        paddingInlineEnd: "55px",
        backgroundColor: "#6C22CA",
        color: uiColors.textInvert,
      }}
    >
      <div
        css={{
          flexShrink: "0",
        }}
      >
        <LightningIcon />
      </div>
      <span
        css={{
          display: "block",
          marginInlineStart: "8px",
          ...typo({
            level: "body2",
            density: "normal",
            weight: "regular",
          }),
        }}
      >
        <Markdown markdown={banner.content} />
      </span>
      <div
        css={{
          position: "absolute",
          right: "12px",
        }}
      >
        <Button
          view="contrastOutline"
          icon={icons.CrossL}
          size="xs"
          ariaLabel="Close"
          onPress={() => setTimeout(closeBannerNotification)}
        />
      </div>
    </div>
  );
};

/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import {
  Suspense,
  useCallback,
  useMemo,
  useRef,
  useState,
  type PropsWithChildren,
} from "react";

import { useFlag } from "@superweb/flags";
import { lazy, Spin, useDebouncedState } from "@superweb/ui";

import { Layout } from "./components/layout";
import {
  NotificationCenterContext,
  type NotificationCenterState,
} from "./context";
import { useMenuItem } from "./menu-item";
import { useFeedsSummary } from "./api";
import { useOverlayNotifications } from "./hooks";

const { NotificationCenter } = lazy(() => import("./notification-center"));

export const NotificationCenterProvider = ({ children }: PropsWithChildren) => {
  const flag = useFlag();

  if (
    !flag({
      id: "b20f9b63-509d-4049-b817-3b9b13b990a5",
      description: "Notification center enabled",
    })
  ) {
    return children;
  }

  return (
    <NotificationCenterProviderInternal>
      {children}
    </NotificationCenterProviderInternal>
  );
};

const NotificationCenterProviderInternal = ({
  children,
}: PropsWithChildren) => {
  const menuRef = useRef<HTMLDivElement>(null);

  const [open, switchNotificationCenterOpen] = useOpenState();

  const feedsUnreadQuery = useFeedsSummary();
  const { banner, closeBanner, notification, closeNotification } =
    useOverlayNotifications();
  const unreadNotifications = feedsUnreadQuery.data?.counts.unread;
  const totalNotifications = feedsUnreadQuery.data?.counts.total;

  const state = useMemo<Omit<NotificationCenterState, "menuItem">>(
    () => ({
      unreadNotifications,
      totalNotifications,
      open,
      menuRef,
      switchNotificationCenterOpen,
      banner,
      closeBanner,
      notification,
      closeNotification,
    }),
    [
      unreadNotifications,
      totalNotifications,
      open,
      switchNotificationCenterOpen,
      banner,
      closeBanner,
      notification,
      closeNotification,
    ],
  );

  const menuItem = useMenuItem(state);

  return (
    <NotificationCenterContext.Provider
      value={{
        ...state,
        menuItem,
      }}
    >
      {children}
      {open && (
        <Suspense
          fallback={
            <Layout
              open={open}
              onOpenChange={switchNotificationCenterOpen}
              main={
                <div
                  css={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <Spin />
                </div>
              }
            />
          }
        >
          <NotificationCenter />
        </Suspense>
      )}
    </NotificationCenterContext.Provider>
  );
};

const useOpenState = () => {
  const [open, internalSetOpen] = useState(false);
  const debouncedOpenValue = useDebouncedState(open, 10);

  const setOpen = useCallback(
    (value?: boolean) => {
      const isOpen = value ?? !debouncedOpenValue;

      internalSetOpen(isOpen);

      return isOpen;
    },
    [debouncedOpenValue],
  );

  return [open, setOpen] as const;
};

/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { Dialog, useTypo } from "@superweb/ui";
import { cssFns } from "@superweb/css";

import type { NotificationFeedLast } from "#internal/api/hiring-partners-app/feeds";

import { Markdown } from "./markdown";
import { useNotificationMetrika } from "../metrika";

type DialogNotificationProps = {
  notification: NonNullable<NotificationFeedLast>;
  closeNotification: () => void;
};

export const DialogNotification = ({
  notification,
  closeNotification,
}: DialogNotificationProps) => {
  const typo = useTypo();
  const { closeFullscreenNotificationMetrika } = useNotificationMetrika();

  const image = notification.media_link || notification.preview_media_link;

  const closeDialogNotification = () => {
    closeNotification();
    closeFullscreenNotificationMetrika();
  };

  return (
    <Dialog
      size="xl"
      title={notification.title}
      onClose={closeDialogNotification}
      content={
        <div
          css={{
            ...cssFns.padding("0", "16px"),
            ...cssFns.overflow("hidden"),
            display: "grid",
          }}
        >
          <div
            css={{
              ...cssFns.flex({ grow: "1", basis: "0" }),
              display: "flex",
              flexDirection: "column",
              overflowX: "auto",
            }}
          >
            {image && (
              <img
                css={{
                  ...cssFns.border({ radius: "16px" }),
                  flexShrink: "0",
                  width: "100%",
                  objectFit: "cover",
                  aspectRatio: "2",
                }}
                src={image}
              />
            )}
            <span
              css={{
                ...typo({
                  level: "body2",
                  density: "tight",
                  weight: "regular",
                }),
              }}
            >
              <Markdown markdown={notification.content} />
            </span>
          </div>
        </div>
      }
    />
  );
};

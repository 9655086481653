/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import type { SVGProps } from "react";
import { cssFns } from "@superweb/css";

const Lightning = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="15"
      height="20"
      viewBox="0 0 15 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.07463 8.14815L10.5561 0L0.5 11.8519H6.06678L4.09147 20L14.8659 8.14815H9.07463Z"
        fill="white"
      />
    </svg>
  );
};

export const LightningIcon = () => {
  return (
    <div
      css={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "32px",
        height: "32px",
        ...cssFns.border({ radius: "100%" }),
        backgroundColor: "#8C4AE1",
      }}
    >
      <Lightning />
    </div>
  );
};

import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  useV1FeedsNotificationListPost,
  useV1FeedsLastServicesFeed,
  type NotificationFeed,
  type NotificationFeedLast,
  type Banner,
} from "#internal/api/hiring-partners-app/feeds";
import { useMessage } from "#internal/intl";

import {
  useFeedNotificationMarkRead,
  useFeedNotificationMarkReadAll,
  useFeedsSummary,
} from "./api";
import { useNotificationCenterState } from "./context";
import { useNotificationMetrika } from "./metrika";

type FeedTag = NotificationFeed["tags"][number];

type TagOption = {
  value: undefined | FeedTag["value"];
  label: FeedTag["label"];
  active: boolean;
};

export const useNotificationsData = () => {
  const { refetch: feedsUnreadRefetch } = useFeedsSummary({ enabled: false });

  const [selectedTag, setSelectedTag] = useState<TagOption>();

  const notificationsQuery = useV1FeedsNotificationListPost({
    tag: selectedTag?.value,
  });
  const firstNotificationQueryPage = notificationsQuery.data?.pages[0];

  const [selectedNotification, setSelectedNotification] =
    useState<NotificationFeed>();

  const tagOptions = useTagOptions({
    tags: firstNotificationQueryPage?.feed_type_tags,
    selectedTag,
  });

  const selectTag = useCallback(
    (tag: TagOption) => {
      setSelectedTag(tag);
      void feedsUnreadRefetch();
    },
    [feedsUnreadRefetch],
  );

  const notifications = useMemo(
    () => notificationsQuery.data?.pages.flatMap((i) => i.feeds) ?? [],
    [notificationsQuery.data],
  );

  const handleSelectNotification = useCallback((value?: NotificationFeed) => {
    setSelectedNotification((prev) =>
      prev?.feed_id === value?.feed_id ? undefined : value,
    );
  }, []);

  const isNotificationSelected = useCallback(
    (value: NotificationFeed) =>
      value.feed_id === selectedNotification?.feed_id,
    [selectedNotification?.feed_id],
  );

  useFeedNotificationMarkReadListener(selectedNotification);
  const { onReadAll } = useFeedNotificationMarkReadAllListener({
    selectedTag,
    firstNotificationPage: firstNotificationQueryPage?.feeds,
  });

  return {
    tags: { selectedTag, options: tagOptions, selectTag },
    notificationsQuery: {
      data: notifications,
      isLoading: notificationsQuery.isLoading,
      isFetchingNextPage: notificationsQuery.isFetchingNextPage,
      hasNextPage: notificationsQuery.hasNextPage,
      fetchNextPage: notificationsQuery.fetchNextPage,
    },
    selectedNotification,
    isNotificationSelected,
    setSelectedNotification: handleSelectNotification,
    onReadAll,
  };
};

const useFeedNotificationMarkReadListener = (
  selectedNotification?: NotificationFeed,
) => {
  const notificationId = selectedNotification?.is_read
    ? undefined
    : selectedNotification?.feed_id;
  const lastNotification = useRef<NotificationFeed["feed_id"]>();

  const { mutate: markRead } = useFeedNotificationMarkRead();

  useEffect(() => {
    const idForRead = lastNotification.current;
    lastNotification.current = notificationId;
    if (idForRead) markRead({ feedId: idForRead, feedType: "notification" });
  }, [notificationId, markRead]);

  useEffect(() => {
    return () => {
      if (lastNotification.current)
        markRead({
          feedId: lastNotification.current,
          feedType: "notification",
        });
      lastNotification.current = undefined;
    };
  }, [markRead]);
};

const useFeedNotificationMarkReadAllListener = ({
  selectedTag,
  firstNotificationPage,
}: {
  selectedTag?: TagOption;
  firstNotificationPage?: NotificationFeed[];
}) => {
  const { unreadNotifications } = useNotificationCenterState();

  const [firstNotificationId, setFirstNotificationId] =
    useState<NotificationFeed["feed_id"]>();

  const showReadAll = Boolean(
    unreadNotifications && unreadNotifications > 0 && firstNotificationId,
  );

  const { mutate: markReadAll } = useFeedNotificationMarkReadAll();

  const handleReadAll = useCallback(() => {
    if (firstNotificationId) markReadAll(firstNotificationId);
  }, [firstNotificationId, markReadAll]);

  const firstNotification = firstNotificationPage?.[0];
  if (
    selectedTag?.value === undefined &&
    firstNotification &&
    firstNotificationId !== firstNotification.feed_id
  ) {
    setFirstNotificationId(firstNotification.feed_id);
  }

  return {
    onReadAll: showReadAll ? handleReadAll : undefined,
  };
};

const useTagOptions = ({
  tags,
  selectedTag,
}: {
  tags?: FeedTag[];
  selectedTag?: TagOption;
}) => {
  const message = useMessage();

  const previousTags = usePrevious(tags);
  const rawTagsData = tags ?? previousTags;

  const options = useMemo<TagOption[]>(() => {
    const tags = rawTagsData ?? [];
    return [
      {
        value: undefined,
        label: message({
          id: "1d9b8cbd-6a70-43f6-88f4-a52ed55e4965",
          context: "Notification center. Tags. All",
          default: "All",
        }),
        active: selectedTag?.value === undefined,
      },
      ...tags.map(({ value, label }) => ({
        value,
        label,
        active: selectedTag?.value === value,
      })),
    ];
  }, [message, rawTagsData, selectedTag]);

  return options;
};

const usePrevious = <T>(value: T): T | undefined => {
  const [previous, setPrevious] = useState<T>();
  const [current, setCurrent] = useState<T>(value);

  if (value !== current) {
    setPrevious(current);
    setCurrent(value);
  }
  return previous;
};

export const useOverlayNotifications = (): {
  banner: Banner | undefined;
  closeBanner: () => void;
  notification: NotificationFeedLast | undefined;
  closeNotification: () => void;
} => {
  const { data } = useV1FeedsLastServicesFeed({
    staleTime: Infinity,
    suspense: true,
    useErrorBoundary: false,
  });

  const [banner, setBanner] = useState<Banner>(data?.banner);
  const [notification, setNotification] = useState<NotificationFeedLast>(
    data?.notification,
  );

  const { mutate: markRead } = useFeedNotificationMarkRead();
  const { openBannerMetrika, openFullscreenNotificationMetrika } =
    useNotificationMetrika();

  useEffect(() => {
    const hasUnreadFullscreenNotification =
      notification && !notification.is_read;

    if (hasUnreadFullscreenNotification) openFullscreenNotificationMetrika();
  }, [notification, openFullscreenNotificationMetrika]);

  useEffect(() => {
    const hasUnreadBanner = banner && !banner.is_read;

    if (hasUnreadBanner) openBannerMetrika();
  }, [banner, openBannerMetrika]);

  const closeBanner = () => {
    if (!banner) return;

    setBanner((prevBanner) =>
      prevBanner ? { ...prevBanner, is_read: true } : undefined,
    );
    markRead({ feedId: banner.feed_id, feedType: "banner" });
  };

  const closeNotification = () => {
    if (!notification) return;

    setNotification((prevNotification) =>
      prevNotification ? { ...prevNotification, is_read: true } : undefined,
    );
    markRead({ feedId: notification.feed_id, feedType: "notification" });
  };

  return { banner, closeBanner, notification, closeNotification };
};

import { useCallback, useMemo } from "react";

import { useMetrika } from "../metrika";

enum MetrikaEvents {
  /** Тап на “Колольчик” (открытие ЦУ) */
  partner_lk_notifications = "partner_lk_notifications",
  /** Закрытие ЦУ */
  partner_lk_close_notifications = "partner_lk_close_notifications",

  /** Тап на конкретную новость */
  partner_lk_notif = "partner_lk_notif",
  /** Тап на вкладки */
  partner_lk_notification_tab = "partner_lk_notification_tab",

  /** Автооткрытие баннера */
  partner_lk_open_banner = "partner_lk_open_banner",
  /** Закрытие баннера */
  partner_lk_close_banner = "partner_lk_close_banner",

  /** Автооткрытие фулскрин уведомления */
  partner_lk_open_fullscreen = "partner_lk_open_fullscreen",
  /** Закрытие фулскрин уведомления */
  partner_lk_close_fullscreen = "partner_lk_close_fullscreen",

  /** Тап на кнопку “Прочитать все” */
  partner_lk_read_all_notifications = "partner_lk_read_all_notifications",
}

type MertikaEventsProps = {
  [MetrikaEvents.partner_lk_notifications]: {
    unread: number | undefined;
    total: number | undefined;
  };
  [MetrikaEvents.partner_lk_close_notifications]: never;
  [MetrikaEvents.partner_lk_notif]: { notification_id: string };
  [MetrikaEvents.partner_lk_notification_tab]: {
    button_text: string | undefined;
  };
  [MetrikaEvents.partner_lk_open_banner]: never;
  [MetrikaEvents.partner_lk_close_banner]: never;
  [MetrikaEvents.partner_lk_open_fullscreen]: never;
  [MetrikaEvents.partner_lk_close_fullscreen]: never;
  [MetrikaEvents.partner_lk_read_all_notifications]: never;
};

export const useNotificationMetrika = () => {
  const { reachGoal } = useMetrika();

  const sendMetrika = useCallback(
    <T extends MetrikaEvents>(metrikaEvent: T) =>
      (params?: MertikaEventsProps[T]) => {
        reachGoal(metrikaEvent, params);
      },
    [reachGoal],
  );

  return useMemo(
    () => ({
      openNotificationCenterMetrika: sendMetrika(
        MetrikaEvents.partner_lk_notifications,
      ),
      closeNotificationCenterMetrika: sendMetrika(
        MetrikaEvents.partner_lk_close_notifications,
      ),
      openNotificationDetailsMetrika: sendMetrika(
        MetrikaEvents.partner_lk_notif,
      ),
      openNotificationTabMetrika: sendMetrika(
        MetrikaEvents.partner_lk_notification_tab,
      ),
      openBannerMetrika: sendMetrika(MetrikaEvents.partner_lk_open_banner),
      closeBannerMetrika: sendMetrika(MetrikaEvents.partner_lk_close_banner),
      openFullscreenNotificationMetrika: sendMetrika(
        MetrikaEvents.partner_lk_open_fullscreen,
      ),
      closeFullscreenNotificationMetrika: sendMetrika(
        MetrikaEvents.partner_lk_close_fullscreen,
      ),
      readAllNotificationsMetrika: sendMetrika(
        MetrikaEvents.partner_lk_read_all_notifications,
      ),
    }),
    [sendMetrika],
  );
};

/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useLayoutEffect, useRef, useState, type ReactNode } from "react";
import { mergeProps, useDialog, useModalOverlay } from "react-aria";
import { useOverlayTriggerState } from "react-stately";

import { cssFns } from "@superweb/css";
import { useIsMobile, useUiColors } from "@superweb/ui";

import { useNotificationCenterState } from "../context";

export const Layout = ({
  open,
  onOpenChange,

  main,
  detailed,
}: {
  open: boolean;
  onOpenChange: (value: boolean) => void;

  main: ReactNode;
  detailed?: ReactNode;
}) => {
  const isMobile = useIsMobile();
  const uiColors = useUiColors();

  const { width: menuWidth } = useMenuWidth();

  const ref = useRef<HTMLDivElement>(null);
  const overlayTriggerState = useOverlayTriggerState({
    isOpen: open,
    onOpenChange,
  });
  const { modalProps, underlayProps } = useModalOverlay(
    { isDismissable: true },
    overlayTriggerState,
    ref,
  );
  const { dialogProps } = useDialog({}, ref);

  const cssBorder = cssFns.border({ radius: isMobile ? "0" : css.padding });

  return (
    <div
      {...underlayProps}
      css={{
        position: "fixed",
        ...cssFns.inset("0"),
        insetInlineStart: menuWidth ? `${menuWidth}px` : "0",
        backgroundColor: uiColors.fog,
        ...cssFns.padding(isMobile ? "0" : css.padding),
      }}
    >
      <div
        ref={ref}
        {...mergeProps(modalProps, dialogProps)}
        css={{
          display: "flex",
          flexDirection: "row",
          alignItems: "stretch",
          width: detailed || isMobile ? "100%" : "50%",
          height: "100%",
          backgroundColor: detailed ? uiColors.background : undefined,
          ...cssBorder,
        }}
      >
        <div
          css={{
            ...cssFns.flex({ grow: "1", shrink: "1", basis: "0" }),
            display: "grid",
            backgroundColor: uiColors.backgroundMinor,
            ...cssBorder,
          }}
        >
          {main}
        </div>
        {Boolean(detailed) && detailed}
      </div>
    </div>
  );
};

const css = {
  padding: "16px",
} as const;

const useMenuWidth = () => {
  const isMobile = useIsMobile();
  const notificationState = useNotificationCenterState();

  const [width, setWidth] = useState<number>();

  useLayoutEffect(() => {
    if (isMobile) {
      setWidth(0);
      return;
    }
    const menuElement = notificationState.menuRef?.current;
    setWidth(menuElement?.offsetWidth);
  }, [isMobile, notificationState.menuRef]);

  return { width };
};

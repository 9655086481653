import { useEffect } from "react";

import type { EditorThemeClasses } from "lexical";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { $convertFromMarkdownString, TRANSFORMERS } from "@lexical/markdown";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";

import { useCss } from "@superweb/css";

type MarkdownProps = {
  markdown: string;
};

const useEditorTheme = (): EditorThemeClasses => {
  const css = useCss();

  const bold = css({
    fontWeight: "bold",
  });
  const italic = css({
    fontStyle: "italic",
  });
  const underline = css({
    textDecorationLine: "underline",
  });
  const strikethrough = css({
    textDecorationLine: "line-through",
  });
  const underlineStrikethrough = css({
    textDecorationLine: "underline line-through",
  });

  return {
    text: {
      bold,
      italic,
      underline,
      strikethrough,
      underlineStrikethrough,
    },
  };
};

const MarkdownText = ({ markdown }: MarkdownProps) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.update(() => {
      $convertFromMarkdownString(markdown, TRANSFORMERS);
    });
  }, [editor, markdown]);

  return (
    <RichTextPlugin contentEditable={<ContentEditable />} placeholder="" />
  );
};

export const Markdown = ({ markdown }: MarkdownProps) => {
  const theme = useEditorTheme();

  return (
    <LexicalComposer
      initialConfig={{
        namespace: "NotificationMarkdown",
        onError(error) {
          throw error;
        },
        editable: false,
        theme,
        nodes: [
          HeadingNode,
          AutoLinkNode,
          LinkNode,
          ListItemNode,
          ListNode,
          QuoteNode,
        ],
      }}
    >
      <MarkdownText markdown={markdown} />
    </LexicalComposer>
  );
};

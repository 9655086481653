/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { cssFns } from "@superweb/css";
import { icons, useTypo, useUiColors } from "@superweb/ui";

import { useMessage } from "#internal/intl";

import type { MenuLink, NotificationCenterState } from "./context";
import { useNotificationMetrika } from "./metrika";

export const useMenuItem = ({
  unreadNotifications,
  totalNotifications,
  open,
  switchNotificationCenterOpen,
}: Pick<
  NotificationCenterState,
  | "unreadNotifications"
  | "open"
  | "switchNotificationCenterOpen"
  | "totalNotifications"
>): MenuLink => {
  const message = useMessage();
  const { openNotificationCenterMetrika } = useNotificationMetrika();

  return {
    type: "link",
    icon: <NotificationCenterMenuIcon badge={unreadNotifications} />,
    label: message({
      id: "305b6660-90d0-4db7-b893-f9fe50c1737b",
      context: "App menu. Notification center menu item title",
      default: "Notification center",
    }),
    link: {
      href: "#notification-center",
      isActive: open,
      onClick: (e) => {
        e.preventDefault();
        e.stopPropagation();
        const instantIsOpen = switchNotificationCenterOpen();
        instantIsOpen &&
          openNotificationCenterMetrika({
            unread: unreadNotifications,
            total: totalNotifications,
          });
      },
    },
  };
};

const NotificationCenterMenuIcon = ({ badge }: { badge?: number }) => {
  const typo = useTypo();
  const uiColors = useUiColors();

  return (
    <div
      css={{
        position: "relative",
        width: css.iconSize,
        height: css.iconSize,
        userSelect: "none",
      }}
    >
      <icons.BellFillL />
      {Boolean(badge) && (
        <span
          css={{
            position: "absolute",
            top: "-50%",
            right: "-50%",

            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: css.badgeSize,
            minHeight: css.badgeSize,

            ...cssFns.padding("2px"),
            boxSizing: "border-box",

            ...cssFns.border({ radius: "16px" }),
            ...typo({ level: "caption1", weight: "medium", density: "tight" }),
            backgroundColor: uiColors.statuses.danger,
            color: uiColors.textInvert,
            textTransform: "none",
          }}
        >
          {badge}
        </span>
      )}
    </div>
  );
};

const css = {
  iconSize: "24px",
  badgeSize: "19px",
};
